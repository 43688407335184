<template>
  <div>
    <b-overlay :show="isLoading" rounded="sm">
      <b-card v-if="order">
        <div
          class="d-flex justify-content-between align-items-center text-center"
        >
          <div class="d-flex">
            <span class="pr-1 padding">
              <h2>ORDER ID</h2>
            </span>
            <span class="bg-primary px-2 padding rounded-lg">
              <h2 class="text-light">{{ order.id }}</h2>
            </span>
          </div>
          <div class="d-flex">
            <span class="pr-1 padding">
              <h2>TOTAL</h2>
            </span>
            <span class="bg-success px-2 padding rounded-lg">
              <h2 class="text-light">
                {{ order.quote ? 'RM ' + order.total_price : 'RM 0.00' }}
              </h2>
            </span>
          </div>
        </div>

        <!-- Date Details -->
        <div class="d-flex justify-content-center align-items-center my-1">
          <hr class="flex-grow-1" />
          <span class="mx-2 font-weight-bold">General Details</span>
          <hr class="flex-grow-1" />
        </div>

        <!-- CREATED ORDER BY -->
        <b-row class="d-flex justify-content-center">
          <b-col md="12">
            <order-data
              title="Created Order By"
              :data="order.order_created_by ? order.order_created_by : '-'"
            />
          </b-col>
        </b-row>

        <!-- PICKUP AND DELIVERY DATE -->
        <b-row class="d-flex justify-content-center">
          <b-col md="4">
            <order-data
              title="Pickup Date"
              :data="$helpers.formatDate(order.pickup_date)"
            />
          </b-col>
          <b-col md="4">
            <order-data
              title="Delivery Date"
              :data="order.quote ? order.quote.delivery_date_format : '-'"
            />
          </b-col>
          <b-col md="4">
            <order-data title="Lorry Size" :data="order.lorry" />
          </b-col>
        </b-row>

        <!-- Sender Details -->
        <div class="d-flex justify-content-center align-items-center my-1">
          <hr class="flex-grow-1" />
          <span class="mx-2 font-weight-bold">Sender Details</span>
          <hr class="flex-grow-1" />
        </div>

        <b-row class="d-flex justify-content-center">
          <b-col md="4">
            <order-data title="Name (Company Name)" :data="order.sender.name" />
          </b-col>
          <b-col md="4">
            <order-data title="Contact No." :data="order.sender.phone" />
          </b-col>
          <b-col md="4">
            <order-data title="Email Address" :data="order.sender.email" />
          </b-col>
        </b-row>

        <b-row class="d-flex justify-content-center">
          <b-col md="12">
            <order-data title="Address" :data="order.sender.complete" />
          </b-col>
        </b-row>

        <!-- Product Details -->
        <div class="d-flex justify-content-center align-items-center my-1">
          <hr class="flex-grow-1" />
          <span class="mx-2 font-weight-bold">Product Details</span>
          <hr class="flex-grow-1" />
        </div>

        <b-row class="d-flex justify-content-center">
          <b-col md="4">
            <order-data
              title="Total No. of Chartered Orders"
              :data="order.chartered_orders.length"
            />
          </b-col>
          <b-col md="2">
            <order-data title="Total Weight" :data="order.weight" />
          </b-col>
          <b-col md="2">
            <order-data title="Total Quantity" :data="order.quantity" />
          </b-col>
          <b-col md="2">
            <order-data
              title="Invoice"
              :data="order.invoice ? order.invoice : '-'"
            />
          </b-col>
          <b-col md="2">
            <order-data title="Insurance" :data="order.insurance" />
          </b-col>
        </b-row>

        <div class="overflow-auto">
          <my-table :rows="rows" :columns="columns" :is-loading="isLoading">
            <template v-slot="{ props }">
              <span v-if="props.column.field === 'id'">
                <span class="bg-primary btn text-white">
                  {{ props.row.id }}
                </span>
              </span>
              <span
                v-else-if="props.column.field === 'packaging'"
                class="d-flex justify-content-center"
              >
                {{
                  props.row.custom_package
                    ? props.row.custom_package
                    : props.row.packaging.text
                }}
              </span>

              <span v-else class="d-flex justify-content-center">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </my-table>
        </div>

        <!-- QUOTE DOCUMENTS   -->
        <div v-if="!isQR" class="mt-3">
          <div class="d-flex justify-content-center align-items-center mb-2">
            <hr class="flex-grow-1" />
            <span class="mx-2 font-weight-bold"> Quoted Documents </span>
            <hr class="flex-grow-1" />
          </div>
          <div class="text-center">
            <div
              v-if="order.quote && order.quote.documents.length"
              class="row justify-content-center"
            >
              <div
                v-for="(doc, index) in order.quote.documents"
                :key="index"
                class="rounded border px-3 py-1 mx-1 mb-1"
              >
                <feather-icon style="color: red" icon="FileIcon" size="25" />
                <a :href="doc.path" target="_blank">
                  {{ doc.name }}
                </a>
              </div>
            </div>
            <div v-else class="d-flex justify-content-center">
              <div>
                <a> No Quoted Document </a>
              </div>
            </div>
          </div>
        </div>

        <!--  Customer Documents    -->
        <div v-if="!isQR" class="mt-3">
          <div class="d-flex justify-content-center align-items-center mb-2">
            <hr class="flex-grow-1" />
            <span class="mx-2 font-weight-bold"> Customer Documents </span>
            <hr class="flex-grow-1" />
          </div>
          <div class="text-center">
            <div
              v-if="order.customer_documents && order.customer_documents.length"
              class="row justify-content-center"
            >
              <div
                v-for="(document, index) in order.customer_documents"
                :key="index"
                class="rounded border px-3 py-1 mx-1 mb-1"
              >
                <feather-icon style="color: red" icon="FileIcon" size="25" />
                <a :href="document.document" target="_blank">
                  {{ document.document_name }}
                </a>
              </div>
            </div>
            <div v-else class="d-flex justify-content-center">
              <div>
                <a> No Customer Document </a>
              </div>
            </div>
          </div>
        </div>

        <!--  Receipts    -->
        <div v-if="!isQR" class="mt-3">
          <div class="d-flex justify-content-center align-items-center mb-2">
            <hr class="flex-grow-1" />
            <span class="mx-2 font-weight-bold"> Receipts </span>
            <hr class="flex-grow-1" />
          </div>
          <b-row>
            <b-col cols="3">
              <div class="rounded border px-2 py-1">
                <feather-icon
                  style="color: red"
                  icon="DownloadIcon"
                  size="15"
                />

                <a href="#" @click.prevent="handleDownload('do')">
                  Delivery Order
                </a>
              </div></b-col
            >
            <b-col cols="3">
              <div class="rounded border px-2 py-1">
                <feather-icon
                  style="color: red"
                  icon="DownloadIcon"
                  size="15"
                />
                <a href="#" @click.prevent="handleDownload('inv')"> Invoice </a>
              </div></b-col
            >
            <b-col cols="3">
              <div class="rounded border py-1">
                <feather-icon
                  style="color: red"
                  icon="DownloadIcon"
                  size="15"
                />
                <a href="#" @click.prevent="handleDownload('grn')">
                  Good Return Note
                </a>
              </div></b-col
            >
            <b-col cols="3">
              <div class="rounded border px-2 py-1">
                <feather-icon
                  style="color: red"
                  icon="DownloadIcon"
                  size="15"
                />

                <a href="#" @click.prevent="handleDownload('cover')">
                  Covernote
                </a>
              </div></b-col
            >
          </b-row>
        </div>

        <!-- END  -->
      </b-card>

      <!-- BACK AND QUOTE BUTTTON -->
      <!-- <div class="d-flex justify-content-between">
          <b-button variant="primary" @click="$router.go(-1)">
            <feather-icon icon="ArrowLeftIcon" size="15" /> Back
          </b-button>
        </div> -->
    </b-overlay>

    <!-- QUOTE AND DOCUMENT MODAL PREVIEW -->
    <b-modal
      ref="modal"
      size="lg"
      :title="modalType === 'quote' ? 'Quoted Document' : 'Customer Document'"
      no-close-on-backdrop
      hide-footer
      centered
    >
      <div
        class="overflow-auto d-flex align-items-center justify-content-center"
        @success="handleModalClosure"
      >
        <template v-if="modalType === 'document'">
          <iframe
            v-if="order.customer_documents && order.customer_documents.length"
            :src="order.customer_documents[modalIndex].document"
            frameborder="3"
            width="700"
            height="750"
            :allowfullscreen="true"
          />
          <div v-else class="text-center">No Document available.</div>
        </template>
        <template v-else-if="modalType === 'quote'">
          <iframe
            v-if="order.quote && order.quote.document"
            :src="order.quote.document"
            frameborder="3"
            width="700"
            height="750"
            :allowfullscreen="true"
          />
          <div v-else class="text-center">No Document available.</div>
        </template>
      </div>
    </b-modal>
  </div>
</template>
  
  <script>
import OrderData from './OrderData.vue'
import MyTable from '@/components/MyTable.vue'

export default {
  components: {
    MyTable,
    OrderData
  },
  data() {
    return {
      isLoading: false,
      isQuoteModalVisible: false,
      isQR: false,
      statusId: 0,
      order: null,
      orderId: this.$route.params.id,
      modalType: null,
      modalIndex: null,
      columns: [
        {
          label: 'ID',
          field: 'id',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Recipient Name',
          field: 'recipient.name',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Email',
          field: 'recipient.email',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Contact',
          field: 'recipient.phone',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Recipient Address',
          field: 'recipient.complete',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Invoice No.',
          field: 'invoice_number',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'PO Number',
          field: 'po_number',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'PO Expiry',
          field: 'po_expiry_date',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'SKU',
          field: 'sku',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Packaging',
          field: 'packaging',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Pallet',
          field: 'packedWithPallets',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Quantity',
          field: 'qty',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Weight',
          field: 'weight',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Size',
          field: 'size',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Temperature',
          field: 'temperature',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Remarks',
          field: 'remarks',
          tdClass: 'text-center align-middle'
        }
      ],
      rows: []
    }
  },

  created() {
    this.isLoading = true
    this.fetchOrders(this.$route.params.id)
  },

  methods: {
    async fetchOrders(id) {
      this.$http.get(`order-summary-chartered/${id}`).then((response) => {
        if (response) {
          this.order = response.data
          this.rows = this.order.chartered_orders
          this.statusId = this.order.status.value
          if (
            this.statusId === 17 ||
            this.statusId === 12 ||
            this.statusId === 14
          ) {
            this.isQR = true
          }
        }
        this.isLoading = false
      })
    },

    quoteOrder() {
      this.isQuoteModalVisible = true
    },

    async handleQuotationSuccess(title, message) {
      this.isQuoteModalVisible = false
      this.$swal({
        icon: 'success',
        title,
        text: message,
        customClass: {
          confirmButton: 'btn btn-success'
        }
      })
    },

    openModal(type, index) {
      this.modalType = type
      this.modalIndex = index
      this.$refs.modal.show()
    },

    handleModalClosure() {
      this.modalType = null
      this.modalIndex = null
    },

    async handleDownload(type) {
      let link = ''
      let fileName = ''
      if (type === 'inv') {
        link = `/download-invoice/${
          this.order.payment.id ? this.order.payment.id : this.order.logpoint_id
        }/${this.order.payment_method}/chartered`
        fileName = `invoice-${this.order.id}`
      } else {
        link = `chartered-delivery-confirmed/${
          this.order.payment_session_id
            ? this.order.payment_session_id
            : this.order.logpoint_id
        }/${this.order.payment_method}/print/${type}`
        fileName = `${type}-${this.order.id}`
      }
      const response = await this.$http.get(link, {
        responseType: 'blob'
      })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', `${fileName}.pdf`)
      document.body.appendChild(fileLink)
      fileLink.click()
    }
  }
}
</script>
  
  <style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
  <style scoped>
.padding {
  padding-top: 5px;
}
</style>
  