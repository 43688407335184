<template>
  <div>
    <ValidationObserver ref="senderDetails">
      <b-row>
        <b-col md="12">
          <b-form-group :label="'Name (or Company Name)'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Name (or Company Name)"
            >
              <input
                v-model="sender.name"
                type="text"
                class="form-control mr-2"
                placeholder="Name (or Company Name)"
                aria-label="Name (or Company Name)"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('email')">
            <ValidationProvider
              rules="required|email"
              v-slot="{ errors }"
              name="E-mail"
            >
              <input
                v-model="sender.email"
                type="text"
                class="form-control mr-2"
                placeholder="E-mail"
                aria-label="E-mail"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="'Contact Number'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Contact Number"
            >
              <input
                v-model="sender.phone"
                type="text"
                class="form-control mr-2"
                placeholder="Contact Number"
                aria-label="Contact Number"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group :label="'Unit Number'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Unit Number"
            >
              <input
                v-model="sender.address.unitNumber"
                type="text"
                class="form-control mr-2"
                placeholder="Unit Number"
                aria-label="Unit Number"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group :label="'Address Line 1'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Address Line 1"
            >
              <input
                v-model="sender.address.addressLine1"
                type="text"
                class="form-control mr-2"
                placeholder="Address Line 1"
                aria-label="Address Line 1"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group :label="'Address Line 2'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Address Line 2"
            >
              <input
                v-model="sender.address.addressLine2"
                type="text"
                class="form-control mr-2"
                placeholder="Address Line 2"
                aria-label="Address Line 2"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group :label="'Postal Code'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Postal Code"
            >
              <input
                v-model="sender.address.postalCode"
                type="text"
                class="form-control mr-2"
                placeholder="Postal Code"
                aria-label="Postal Code"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="'Area'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Area"
            >
              <input
                v-model="sender.address.area"
                type="text"
                class="form-control mr-2"
                placeholder="Area"
                aria-label="Area"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group :label="'State'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="State"
            >
              <v-select
                v-model="sender.address.state"
                :options="states"
                :clearable="false"
                label="text"
                :reduce="(state) => state.value"
                :placeholder="$t('select_a_state')"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="'Country'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Country"
            >
              <v-select
                v-model="sender.address.country"
                :options="countries"
                :clearable="false"
                label="text"
                :reduce="(country) => country.value"
                :placeholder="$t('select_a_country')"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  name: 'SenderDetails',
  components: {
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'mr-2'
      },
      resetSize: {
        size: null,
        length: null,
        width: null,
        height: null
      },
      isAdditionalStop: false,
      lorryTooltipContent: '',
      sender: { address: {}},
      countries: [
        { text: 'Malaysia', value: 'Malaysia' },
        { text: 'Singapore', value: 'Singapore' }
      ],
      states: [
        { text: 'Kuala Lumpur', value: 'Kuala Lumpur' },
        { text: 'Perlis', value: 'Perlis' },
        { text: 'Kedah', value: 'Kedah' },
        { text: 'Pulau Pinang', value: 'Pulau Pinang' },
        { text: 'Perak', value: 'Perak' },
        { text: 'Pahang', value: 'Pahang' },
        { text: 'Kelantan', value: 'Kelantan' },
        { text: 'Terengganu', value: 'Terengganu' },
        { text: 'Selangor', value: 'Selangor' },
        { text: 'Negeri Sembilan', value: 'Negeri Sembilan' },
        { text: 'Melaka', value: 'Melaka' },
        { text: 'Johor', value: 'Johor' },
        { text: 'Sabah', value: 'Sabah' },
        { text: 'Sarawak', value: 'Sarawak' },
        { text: 'Singapore', value: 'Singapore' }
      ]
    }
  },
  methods: {
    childMethod() {
      return new Promise((resolve, reject) => {
        var result = ''
        this.$refs.senderDetails.validate().then((success) => {
          if (success) {
            this.$emit('setSender', this.sender)
            result = true
            resolve(result)
          } else {
            result = false
          }
          resolve(result)
        })
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
